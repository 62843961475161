import UserInterface from "@/Entity/UserInterface";
import Utils from "@/Service/Utils/Utils";
import { Module } from "vuex";
import State from "./State";
import StateInterface from "./StateInterface";
import { Axios, AxiosError, AxiosInstance, AxiosPromise, AxiosRequestTransformer, AxiosResponse, AxiosResponseHeaders, AxiosResponseTransformer, RawAxiosResponseHeaders } from "axios";

const module: Module<StateInterface, any> = {
	namespaced: true,
	state: () => new State(),
	getters: {
		user: state => state.user,
		userIsLoading: state => state.userIsLoading,
		userIsSending: state => state.userIsSending,
		userSendingResult: state => state.userSendingResult
	},
	mutations: {
		setUser(state: StateInterface, payload: UserInterface) {
			state.user = payload;
		},
		setUserIsLoading(state: StateInterface, payload: boolean) {
			state.userIsLoading = payload;
		},
		setUserIsSending(state: StateInterface, payload: boolean) {
			state.userIsSending = payload;
		},
		setUserSendingResult(state: StateInterface, payload: AxiosResponse) {
			state.userSendingResult = payload;
		}
	},
	actions: {		
		async createUser(context, params: UserInterface) {
			context.commit('setUserIsSending', true);
			try {
				const result = await Utils.fetchData<boolean>('POST', 'create', 'user', params);
				context.commit('setUserSendingResult', result);
				
				if(result.status === 200 && !!result.data) {
					context.commit('setUser', result.data.data);
				}
				console.log(result.status);
			} catch (error) {
				context.commit('setUserSendingResult', (error as AxiosError).response);
				console.error(`request error: ${error}`);
			}
			context.commit('setUserIsSending', false);
		},
	},
}
export default module;