import UserInterface from "@/Entity/UserInterface";
import Utils from "@/Service/Utils/Utils";
import { Module } from "vuex";
import State from "./State";
import StateInterface from "./StateInterface";

const module: Module<StateInterface, any> = {
	namespaced: true,
	state: () => new State(),
	getters: {
		userList: state => state.userList,
		userListIsLoading: state => state.userListIsLoading,
		userTotalCount: state => state.userTotalCount
	},
	mutations: {
		setUserList(state: StateInterface, payload: Array<UserInterface>) {
			state.userList = payload;
		},
		setUserListIsLoading(state: StateInterface, payload: boolean) {
			state.userListIsLoading = payload;
		},
		setUserTotalCount(state: StateInterface, payload: number) {
			state.userTotalCount = payload;
		},
	},
	actions: {
		async getUserList(context, params) {
			try {
				context.commit('setUserListIsLoading', true);
				const result = await Utils.fetchData<Array<UserInterface>>('POST', 'get', 'user', params);
				console.log(result);
				
				if(result.status === 200 && !!result.data.data) {
					context.commit('setUserList', result.data.data);

					if(result.data.count) {
						context.commit('setUserTotalCount', result.data.count);
					}
				}
				context.commit('setUserListIsLoading', false);
				return true;
			} catch (error) {
				context.commit('setUserListIsLoading', false);
				console.error(`request error: ${error}`);
				return false
			}
		},
	},
}
export default module;