import UserInterface from "@/Entity/UserInterface";
import Utils from "@/Service/Utils/Utils";
import { Module } from "vuex";
import State from "./State";
import StateInterface from "./StateInterface";
import RoleInterface from "@/Entity/RoleInterface";

const module: Module<StateInterface, any> = {
	namespaced: true,
	state: () => new State(),
	getters: {
		roleList: state => state.roleList,
		roleListIsLoading: state => state.roleListIsLoading,
		roleTotalCount: state => state.roleTotalCount
	},
	mutations: {
		setRoleList(state: StateInterface, payload: Array<RoleInterface>) {
			state.roleList = payload;
		},
		setRoleListIsLoading(state: StateInterface, payload: boolean) {
			state.roleListIsLoading = payload;
		},
		setRoleTotalCount(state: StateInterface, payload: number) {
			state.roleTotalCount = payload;
		},
	},
	actions: {
		async getRoleList(context, params) {
			try {
				context.commit('setRoleListIsLoading', true);
				const result = await Utils.fetchData<Array<RoleInterface>>('POST', 'get', 'roles', params);
				console.log(result);
				
				if(result.status === 200 && !!result.data.data) {
					context.commit('setRoleList', result.data.data);

					if(result.data.count) {
						context.commit('setRoleTotalCount', result.data.count);
					}
				}
				context.commit('setRoleListIsLoading', false);
				return true;
			} catch (error) {
				context.commit('setRoleListIsLoading', false);
				console.error(`request error: ${error}`);
				return false
			}
		},
	}
}
export default module;