/* eslint-disable */
import { createApp } from "vue";
import App from '@/App.vue';
import router from '@/router';
import store from '@/Store';
import clickOutside from "@/Service/ClickOutside";
import CKEditor from '@ckeditor/ckeditor5-vue';


export const app = createApp(App)
	.use(store)
	.use(router)
    .use(CKEditor)
    
	.directive('click-outside', clickOutside);

app.mount('#app');
