/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Utils from './Service/Utils/Utils';
import ModuleInterface from './Entity/ModuleInterface';
import Home from '@/Pages/Home.vue';
import Authorization from '@/Pages/Authorization.vue';
import NotFound from '@/Pages/404.vue';
import { Component, defineAsyncComponent } from 'vue';

const modules = async (params: any = []) => { // get moduel from server
	try {
		const result = await Utils.fetchData<Array<ModuleInterface>>('POST', 'get', 'module', params);
		
		if(result.status === 200 && !!result.data) {
			return result.data.data;
		}

		return [];
	} catch (error) {
		console.error(`request error: ${error}`);
		return [];
	}
}

const moduleList = await modules({ isDelete: false });

const routes: Array<RouteInterface> = [
	{ path: '/', name: 'home', title: 'Главная', component: Home, meta: { requiresAuth: true } },
	{ path: '/authorization', name: 'authorization', title: 'Авторизация', component: Authorization },
	{ path: '/:pathMatch(.*)*', name: '404', title: '', component: NotFound, meta: { requiresAuth: true } },
] 

for (const iterator of moduleList) { // create dinamic module route 
	const component: Component = defineAsyncComponent(() => import(`@/Pages/${Utils.toCamelCase(iterator.breadcrumb)}.vue`));

	const route: RouteInterface = {
		path: `/${iterator.breadcrumb}`,
		name: iterator.breadcrumb,
		title: iterator.title,
		component: component,
		meta: { 
			requiresAuth: true 
		}
	};

	routes.push(route);
}

// export default
export default createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes,
});

// Route Interface 
export type RouteInterface = RouteRecordRaw & {
	title?: string;
}