import UserInterface from "@/Entity/UserInterface";
import Utils from "@/Service/Utils/Utils";
import { Module } from "vuex";
import State from "./State";
import StateInterface from "./StateInterface";

const module: Module<StateInterface, any> = {
	namespaced: true,
	state: () => new State(),
	getters: {
		user: state => state.user,
		userIsLoading: state => state.userIsLoading,
		userIsSending: state => state.userIsSending
	},
	mutations: {
		setUser(state: StateInterface, payload: UserInterface) {
			state.user = payload;
		},
		setUserIsLoading(state: StateInterface, payload: boolean) {
			state.userIsLoading = payload;
		},
		setUserIsSending(state: StateInterface, payload: boolean) {
			state.userIsSending = payload;
		}
	},
	actions: {
		async getUser(context, params) {
			try {
				context.commit('setUserIsLoading', true);
				const result = await Utils.fetchData<Array<UserInterface>>('POST', 'get', 'user', params);

				if(result.status === 200 && !!result.data) {
					context.commit('setUser', result.data.data[0]);
				}

				context.commit('setUserIsLoading', false);
				return true;
			} catch (error) {
				context.commit('setUserIsLoading', false);
				console.error(`request error: ${error}`);
				return false
			}
		},
		async updateUser(context, params: UserInterface) {
			context.commit('setUserIsSending', true);
			
			try {
				const result = await Utils.fetchData<boolean>('POST', 'update', 'user', params);
			} catch (error) {
				console.error(`request error: ${error}`);
			}
			context.commit('setUserIsSending', false);
		},
	},
}
export default module;